<template>
<b-card no-body>
    <validation-observer ref="VForm">
        <b-form @submit.prevent="doSubmit">
            <b-card-header>
                <b-row>
                <b-col lg="8">
                    <h5 class="card-title">{{ $parent.isAdd?'Tambah':'Edit' }} {{ $parent.pageTitle }}</h5>
                </b-col>
                </b-row>
            </b-card-header>
            <b-card-body>
                <div class="form-row">
                <div class="form-group col-md-9">
                    <label>Nama Pasien <small class="txt_mandatory">*</small></label>
                    <div class="form-row">
                        <div class="col-md-6">
                            <v-select class="diagnosa-section select-paging" placeholder="Pilih Pasien"
                                v-model="row.absd_ap_id"
                                @search="filterName($event)"
                                @input="selectName($event)"
                                :options="mPasien" label="text"
                                :clearable="true" :reduce="v=>v.value">
                                
                                <template slot="selected-option">
                                <span v-b-tooltip.hover.right :title="row.ap_fullname">
                                    {{row.ap_fullname||"-"}} - {{row.ap_code||"-"}}
                                </span>
                                </template>
                                
                                <template slot="option" slot-scope="option">
                                <span v-b-tooltip.hover.right :title="option.text">
                                {{ option.text }} - {{option.code}}
                                </span>
                                </template>
                                
                                <template #list-footer v-if="(mPasien||[]).length >= 1">
                                <li class="list-paging">
                                    <div class="d-flex align-items-center justify-content-between">
                                    <b-button @click="changePage(mPasien[0].currentPage,'min')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-left22 text-white"></i></b-button>
                                    <small>Halaman {{mPasien[0].currentPage}} dari
                                        {{ceilData(mPasien[0].totalRows/10)}}</small>
                                    <b-button @click="changePage(mPasien[0].currentPage,'plus')"
                                        size="sm" variant="transparent" class="btn-icon bg-blue-300"><i
                                        class="icon-arrow-right22 text-white"></i></b-button>
                                    </div>
                                </li>
                                </template>
                            </v-select>
                            
                            <VValidate 
                                name="Nama Pasien" 
                                v-model="row.arres_ap_id" 
                                :rules="toValidate(mrValidation.arres_ap_id)"
                            />
                        </div>

                        <div class="col-md-6">
                        <v-select placeholder="Data Registrasi" @input="selectReg($event)" v-model="row.absd_ar_id" :options="dataReg" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Data Registrasi" 
                            v-model="row.absd_ar_id" 
                            :rules="toValidate(mrValidation.absd_ar_id)"
                        />
                        </div>
                    </div>
                </div>
                </div>
                

                <template v-if="Object.keys(row||{}).length && row.absd_ap_id && row.absd_ar_id">
                    <div class="card mb-1">
                        <div class="card-body bg_card_blue">
                            <div class="row">
                            <div class="col-md-9">
                                <table class="table table-borderless table-sm text-uppercase">
                                <tbody>
                                    <tr>
                                    <td width="33%">
                                        <div class="result_tab">
                                        <h4>No. Rekam Medis</h4>
                                        <p>{{row.ap_code||"-"}}</p>
                                        </div>
                                    </td>
                                    <td width="33%">
                                        <div class="result_tab">
                                        <h4>Nama Pasien</h4>
                                        <p>{{row.ap_fullname||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td width="33%">
                                        <div class="result_tab">
                                        <h4>Tempat, Tanggal Lahir </h4>
                                        <p>{{row.ap_pob||"-"}}, {{row.ap_dob | moment("DD MMMM YYYY")}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Usia</h4>
                                        <p>{{row.ap_usia_with_ket||"-"}}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="result_tab">
                                        <h4>Jenis Kelamin</h4>
                                        <p>{{row.cg_label||"-"}}</p>
                                        </div>
                                    </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </template>

                
                <template v-if="errData">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-danger">
                            <h6 class="mb-0">{{errData||"-"}}</h6>
                            </div>
                        </div>
                    </div>
                </template>

                
                <template v-if="errDataV2">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="alert alert-danger">
                            <h6 class="mb-0">{{errDataV2||"-"}}</h6>
                            </div>
                        </div>
                    </div>
                </template>

                <!--  v-if="Object.keys(row||{}).length && row.absd_ap_id" -->
                <div v-if="Object.keys(row||{}).length && row.absd_ap_id && !errData" class="row">
                  <div class="col-md-6 pt-3" style="border-right: 1px solid #ccc;">
                    <div class="row">
                      <div class="form-group col-md-3">
                        <label>Tanggal SEP</label>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="row.absd_tgl_sep" disabled>
                                </datepicker>
                            </div>
                      </div>


                      <div class="form-group col-md-9">
                        <label>Nomor Peserta BPJS <small class="txt_mandatory">*</small></label>
                        <b-form-input v-model="row.absd_no_kartu" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                          placeholder="Nomor Peserta"/>
                            <VValidate 
                                name="Nomor Peserta BPJS" 
                                v-model="row.absd_no_kartu" 
                                :rules="toValidate(mrValidation.absd_no_kartu)"
                            />
                        
                      </div>

                      <div class="form-group col-md-6">
                        <label>Asal Rujukan <small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Asal Rujukan" v-model="row.absd_asal_rujukan" :options="Config.mr.jenisFaskes" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Asal Rujukan" 
                            v-model="row.absd_asal_rujukan" 
                            :rules="toValidate(mrValidation.absd_asal_rujukan)"
                        />
                        
                      </div>

                      <div class="form-group col-md-6">
                            <label>Tanggal Rujukan</label>
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="row.absd_tgl_rujuk">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                            <VValidate 
                                name="Tanggal Rujukan BPJS" 
                                v-model="row.absd_tgl_rujuk" 
                                :rules="toValidate(mrValidation.absd_tgl_rujuk)"
                            />
                            
                      </div>


                      <div class="form-group col-md-6">
                            <label>Nomor Rujukan</label>
                            <b-form-input v-model="row.absd_no_rujuk" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                            placeholder="Nomor Rujukan"/>

                            <VValidate 
                                name="Nomor Rujukan BPJS" 
                                v-model="row.absd_no_rujuk" 
                                :rules="toValidate(mrValidation.absd_no_rujuk)"
                            />
                      </div>
                      
                      <div class="form-group col-md-6">
                            <label>Nomor LP</label>
                            <b-form-input v-model="row.absd_no_lp" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                            placeholder="Nomor LP"/>

                            <VValidate 
                                name="Nomor LP BPJS" 
                                v-model="row.absd_no_lp" 
                                :rules="toValidate(mrValidation.absd_no_lp)"
                            />
                      </div>


                      <div class="form-group col-md-12">
                        <label>PPK Rujukan</label>
                        <div class="form-row">
                            <div class="col-md-4"> 
                            <b-form-input @input="getMasterPPKRujukan($event)" v-model="row.absd_ppk_rujukan" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                            placeholder="PPK Rujukan"/>
                            </div>
                            <div class="col-md-8"> 
                                <b-form-input disabled  v-model="row.absd_nama_fakes_rujuk" type="text" name="" id="" class="form-control"
                                    placeholder="PPK Rujukan"/></div>
                            </div>
                            <VValidate 
                                name="PPK Rujukan BPJS" 
                                v-model="row.absd_ppk_rujukan" 
                                :rules="toValidate(mrValidation.absd_ppk_rujukan)"
                            />
                            <VValidate 
                                name="Nama Fakes Rujukan BPJS" 
                                v-model="row.absd_nama_fakes_rujuk" 
                                :rules="toValidate(mrValidation.absd_nama_fakes_rujuk)"
                            />
                      </div>

                      <div class="form-group col-md-12">
                        <label>Dokter DPJP</label>
                        <div class="form-row">
                            <div class="col-md-4"> 
                            <b-form-input @input="getMasterDPJP($event)" v-model="row.absd_dpjp" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                            placeholder="Kode Dokter DPJP"/>
                            </div>
                            <div class="col-md-8"> 
                                <b-form-input disabled v-model="row.absd_dpjp_name" type="text" name="" id="" class="form-control"
                                    placeholder="Dokter DPJP"/></div>
                            </div>
                            <VValidate 
                                name="Kode Dokter DPJP BPJS" 
                                v-model="row.absd_dpjp" 
                                :rules="toValidate(mrValidation.absd_dpjp)"
                            />
                            <VValidate 
                                name="Dokter DPJP BPJS" 
                                v-model="row.absd_dpjp_name" 
                                :rules="toValidate(mrValidation.absd_dpjp_name)"
                            />
                      </div>

                      <div class="form-group col-md-12">
                        <label>No. SKDP/SPRI</label>
                        <b-form-input v-model="row.absd_no_skdp" type="text" name="" id="" class="form-control" placeholder="No. SKDP/SPRI"/>
                        <VValidate 
                            name="No. SKDP/SPRI" 
                            v-model="row.absd_no_skdp" 
                            :rules="toValidate(mrValidation.absd_no_skdp)"
                        />
                      </div>

                        <!--
                      <div class="form-group col-md-12">
                        <label>Diagnosa Awal</label>
                        <b-form-input v-model="row.absd_diagnosa_pelayanan" type="text" name="" id="" class="form-control" placeholder="Kode Diagnosa"/>
                        <VValidate 
                            name="Kode Diagnosa" 
                            v-model="row.absd_diagnosa_pelayanan" 
                            :rules="toValidate(mrValidation.absd_diagnosa_pelayanan)"
                        />
                      </div>
                      -->

                      <div class="form-group col-md-12">
                        <label>Diagnosa</label>
                        <div class="form-row">
                            <div class="col-md-4"> 
                            <b-form-input @input="getMasterDiagnosa($event)" v-model="row.absd_diagnosa_pelayanan" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                            placeholder="Kode Diagnosa"/>
                            </div>
                            <div class="col-md-8"> 
                                <b-form-input disabled v-model="row.absd_diagnosa_pelayanan_text" type="text" name="" id="" class="form-control"
                                    placeholder="Diagnosa"/></div>
                            </div>
                            <VValidate 
                                name="Kode Diagnosa BPJS" 
                                v-model="row.absd_diagnosa_pelayanan" 
                                :rules="toValidate(mrValidation.absd_diagnosa_pelayanan)"
                            />
                            <VValidate 
                                name="Diagnosa BPJS" 
                                v-model="row.absd_diagnosa_pelayanan_text" 
                                :rules="toValidate(mrValidation.absd_diagnosa_pelayanan_text)"
                            />
                      </div>


                      <div class="form-group col-md-6">
                        <label>POLI Tujuan</label>
                        <v-select placeholder="Poli Tujuan" v-model="row.absd_kd_pelayanan" :options="mPoli" label="nmpoli" :clearable="true" :reduce="v=>v.kdpoli"></v-select>
                        <VValidate 
                            name="Nomor Poli Tujuan" 
                            v-model="row.absd_kd_pelayanan" 
                            :rules="toValidate(mrValidation.absd_kd_pelayanan)"
                        />
                      </div>

                      <div class="form-group col-md-12">
                        <label>PPK Pelayanan</label>
                        <div class="form-row">
                            <div class="col-md-4"> 
                                <b-form-input @input="getMasterPPKPelayanan($event)" v-model="row.absd_ppk_pelayanan" type="text" name="NomorPelayanan" id="NomorPelayanan" class="form-control"
                                placeholder="PPK Pelayanan"/>
                            </div>
                            <div class="col-md-8"> 
                                <b-form-input disabled  v-model="row.absd_nama_fakes_pelayanan" type="text" name="" id="" class="form-control"
                                    placeholder="PPK Pelayanan"/>
                            </div>
                            <VValidate 
                                name="PPK Pelayanan BPJS" 
                                v-model="row.absd_ppk_pelayanan" 
                                :rules="toValidate(mrValidation.absd_ppk_pelayanan)"
                            />
                            <VValidate 
                                name="Nama Fakes Pelayanan BPJS" 
                                v-model="row.absd_nama_fakes_pelayanan" 
                                :rules="toValidate(mrValidation.absd_nama_fakes_pelayanan)"
                            />
                        </div>
                      </div>

                      <div class="form-group col-md-6">
                        <label>Jenis Pelayanan <small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Jenis Pelayanan" v-model="row.absd_jenis" :options="Config.mr.jenisPelayanan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Nomor Jenis Pelayanan" 
                            v-model="row.absd_jenis" 
                            :rules="toValidate(mrValidation.absd_jenis)"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Eksekutif <small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Eksekutif" v-model="row.absd_eksekutif" :options="Config.mr.yesNoOptV3" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Eksekutif" 
                            v-model="row.absd_eksekutif" 
                            :rules="toValidate(mrValidation.absd_eksekutif)"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>COB <small class="txt_mandatory">*</small></label>
                        <v-select placeholder="COB" v-model="row.absd_cob" :options="Config.mr.yesNoOptV3" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="COB" 
                            v-model="row.absd_cob" 
                            :rules="toValidate(mrValidation.absd_cob)"
                        />
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6 pt-3">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label>Katarak <small class="txt_mandatory">*</small></label>
                        <v-select placeholder="Katarak" v-model="row.absd_katarak" :options="Config.mr.yesNoOptV3" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Katarak" 
                            v-model="row.absd_katarak" 
                            :rules="toValidate(mrValidation.absd_katarak)"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>KLL <small class="txt_mandatory">*</small></label>
                        <v-select placeholder="KLL" v-model="row.absd_laka_lantas" :options="Config.mr.configLakaLantas" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="KLL" 
                            v-model="row.absd_laka_lantas" 
                            :rules="toValidate(mrValidation.absd_laka_lantas)"
                        />
                      </div>

                      <div class="form-group col-md-12">
                        <label>Keterangan</label>
                        <b-form-input v-model="row.absd_catatan" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                          placeholder="Keterangan"/>
                      </div>

                    <template v-if="row.absd_laka_lantas != 0">
                      <div class="form-group col-md-12">
                        <label>Suplesi <small class="txt_mandatory">*</small></label>
                        <div class="row">
                          <div class="col-md-5">
                            <v-select placeholder="suplesi" v-model="row.absd_is_kll" :options="Config.mr.yesNoOptV3" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            <VValidate 
                                name="suplesi" 
                                v-model="row.absd_is_kll" 
                                :rules="toValidate(mrValidation.absd_is_kll)"
                            />
                          </div>
                          <div class="col-md-7" v-if="row.absd_is_kll == 'Y'">
                            <b-form-input v-model="row.absd_no_sep_suplesiname" type="text" class="form-control"
                              placeholder="SEP Suplesi" />
                            
                            <VValidate 
                                name="SEP suplesi" 
                                v-model="row.absd_no_sep_suplesiname" 
                                :rules="toValidate(mrValidation.absd_no_sep_suplesiname)"
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <div class="input-group mb-3">
                                <datepicker input-class="form-control transparent"
                                placeholder="Pilih Tanggal Kejadian" class="my-datepicker"
                                calendar-class="my-datepicker_calendar" v-model="row.absd_tanggal_kll">
                                </datepicker>
                                <div class="input-group-append calendar-group">
                                <span class="input-group-text" id="basic-addon2"><i
                                    class="icon-calendar"></i></span>
                                </div>
                            </div>
                            <VValidate 
                                name="Tanggal KLL BPJS" 
                                v-model="row.absd_tanggal_kll" 
                                :rules="toValidate(mrValidation.absd_tanggal_kll)"
                            />  
                        </div>
                        </div>
                      </div>

                      <div class="form-group col-md-6">
                        <label>Provinsi KLL <small class="txt_mandatory">*</small></label>
                        <v-select @input="getMasterKabupaten($event);selectprovinsi($event)" placeholder="Provinisi KLL" v-model="row.absd_provinsi" :options="mProvinsi" label="nama" :clearable="true" :reduce="v=>v.kode"></v-select>
                        <VValidate 
                            name="Provinisi KLL" 
                            v-model="row.absd_provinsi" 
                            :rules="toValidate(mrValidation.absd_provinsi)"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Kabupaten KLL <small class="txt_mandatory">*</small></label>
                        <v-select @input="getMasterKecamatan($event);selectkabupaten($event)" placeholder="Kabupaten KLL" v-model="row.absd_kabupaten" :options="mKabupaten" label="nama" :clearable="true" :reduce="v=>v.kode"></v-select>
                        <VValidate 
                            name="Kabupaten KLL" 
                            v-model="row.absd_kabupaten" 
                            :rules="toValidate(mrValidation.absd_kabupaten)"
                        />
                      
                      </div>

                      <div class="form-group col-md-6">
                        <label>Kecamatan KLL <small class="txt_mandatory">*</small></label>
                        <v-select @input="selectkecamatan($event)" placeholder="Kecamatan KLL" v-model="row.absd_kecamatan" :options="mKecamatan" label="nama" :clearable="true" :reduce="v=>v.kode"></v-select>
                        <VValidate 
                            name="Kecamatan KLL" 
                            v-model="row.absd_kecamatan" 
                            :rules="toValidate(mrValidation.absd_kecamatan)"
                        />
                      </div>
                    </template>

                      <div class="form-group col-md-6">
                        <label>Tujuan Kunjungan <small class="txt_mandatory">*</small></label>
                        <v-select  placeholder="Tujuan Kunjungan" v-model="row.absd_tujuan_kunjungan" :options="Config.mr.configTujuan" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Tujuan Kunjungan" 
                            v-model="row.absd_tujuan_kunjungan" 
                            :rules="toValidate(mrValidation.absd_tujuan_kunjungan)"
                        />
                      </div>

                    <template v-if="row.absd_tujuan_kunjungan != 0">
                      <div class="form-group col-md-6">
                        <label>Flag Prosedur <small class="txt_mandatory">*</small></label>
                        <v-select  placeholder="Flag Prosedur" v-model="row.absd_flag" :options="Config.mr.configFlag" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Flag Prosedur" 
                            v-model="row.absd_flag" 
                            :rules="toValidate(mrValidation.absd_flag)"
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Penunjang <small class="txt_mandatory">*</small></label>
                        <v-select  placeholder="Penunjang" v-model="row.absd_penunjang" :options="Config.mr.configPenunjang" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                        <VValidate 
                            name="Penunjang" 
                            v-model="row.absd_penunjang" 
                            :rules="toValidate(mrValidation.absd_penunjang)"
                        />
                      </div>
                    </template>

                      <div class="form-group col-md-8">
                        <label>DPJP Layanan</label>
                        <div class="form-row">
                            <div class="col-md-4"> 
                                <b-form-input v-model="row.absd_dpjp_layanan" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                                placeholder="Nomor Peserta"/>
                            </div>
                            <div class="col-md-8"> 
                                <b-form-input v-model="row.absd_assesmen_layanan" type="text" name="NomorRujukan" id="NomorRujukan" class="form-control"
                                placeholder="DPJP Layanan"/>
                            </div>
                        </div>
                        <VValidate 
                            name="DPJP Layanan" 
                            v-model="row.absd_dpjp_layanan" 
                            :rules="toValidate(mrValidation.absd_dpjp_layanan)"
                        />
                        <VValidate 
                            name="Assesmen Layanan" 
                            v-model="row.absd_assesmen_layanan" 
                            :rules="toValidate(mrValidation.absd_assesmen_layanan)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                
            </b-card-body>
            
            <div class="card-footer">
            <div class="text-right">
                <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
            </div>
        </b-form>
    </validation-observer>
</b-card>
</template>

<script>
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
import Datepicker from 'vuejs-datepicker'

export default{
  components: {
    Datepicker
  },
  props:{
    row:Object,
    mrValidation:Object,
    mPasien: Array,
    mKecamatan : Array,
    mKabupaten : Array,
    mProvinsi : Array,
    mPoli : Array,
    dataReg : Array,
    Config:Object
  },
  
    data() {
        return {
            searchData: null,
            errData: null,
            errDataV2: null,
        }
    },
  methods: {
    selectReg(e){
        let data = {
            search : e,
            type : 'select-dokter-data'
        }
        Gen.apiRest(
            "/do/"+this.$parent.modulePage, 
            {
                data: data
            }, "POST"
        ).then(res=>{
            this.row.absd_dpjp = res.data.bu_dokter_bpjs_id
            this.row.absd_dpjp_name = res.data.bu_full_name
        })
    },

    toValidate(val){
      return {...val}
    },

    selectprovinsi(e){
        let index = this.mProvinsi.findIndex(x => x.kode == e)
        if (index !== -1) {
            this.row.absd_provinsi_text = this.mProvinsi[index]['nama']
        }
    },
    selectkabupaten(e){
        let index = this.mKabupaten.findIndex(x => x.kode == e)
        if (index !== -1) {
            this.row.absd_kabupaten_text = this.mKabupaten[index]['nama']
        }
    },
    selectkecamatan(e){
        let index = this.mKecamatan.findIndex(x => x.kode == e)
        if (index !== -1) {
            this.row.absd_kecamatan_text = this.mKecamatan[index]['nama']
        }
        console.log(this.row)
    },
    
    doSubmit(){
        this.$refs['VForm'].validate().then(success => {
            if(!success){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                }).then(result => {
                    if (result.value) {
                        setTimeout(() => {
                            let inv = []
                            let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                            for (let i = 0; i < (el || []).length; i++) {
                                if (el[i].style.display !== 'none') {
                                inv.push(el[i].id)
                                }
                            }
                        },1000)
                    }
                })
            }
            
            if (success) {
                this.$swal({
                icon: 'warning',
                title: 'Apakah Anda Yakin akan menyimpan data ini?',
                showCancelButton: true,
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                if (result.value) {
                    let data = this.row
                    data.type = this.$parent.pageSlug == 'add' ? 'add' :'update'
                    
                    this.loadingOverlay = true
                        Gen.apiRest(
                        "/do/" + this.$parent.modulePage, {
                            data: data
                        },
                    "POST"
                    ).then(res => {
                        this.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
                        this.$swal({
                            title: resp.message,
                            icon: resp.status,
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            if (result.value) {
                                setTimeout(()=>{
                                    this.$router.push({name:this.$route.name}).catch(()=>{})
                                },1000)
                            }
                        })
                    }).catch(err => {
                        this.loadingOverlay = false
                        if (err) {
                            err.statusType = err.status
                            err.status = "error"
                            err.title = err.response?.data?.title
                            err.message = err.response?.data?.message
                            err.messageError = err.message
                        }
                        this.$parent.doSetAlertForm(err)
                    })

                    // 934?regId=1367
                }
                })
            }

        })
    },
    
    back() {
        this.$router.back()
    },


    getMasterDiagnosa(e){
        let data = {
            search : e,
            type : 'select-diagnosa'
        }
        Gen.apiRest(
            "/do/"+this.$parent.modulePage, 
            {
                data: data
            }, "POST"
        ).then(res=>{
            if(res.data.status == "success"){
                this.row.absd_diagnosa_pelayanan_text = res.data.response.diagnosa[0]['nama']            
            }else{
                this.row.absd_diagnosa_pelayanan_text = null            
            }
        })
    },

    getMasterPPKRujukan(e){
        if(e.length == 8){
            let data = {
                search : e,
                jenis: this.row.absd_asal_rujukan,
                type : 'select-ppk-rujukan'
            }
            Gen.apiRest(
                "/do/"+this.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                if(res.data.status == "success"){
                    this.row.absd_nama_fakes_rujuk = res.data.response.faskes[0]['nama']
                }else{
                    this.row.absd_nama_fakes_rujuk = null
                }
            })
        }else{
            this.row.absd_nama_fakes_rujuk = null
        }
    },
    getMasterPPKPelayanan(e){
        if(e.length == 8){
            let data = {
                search : e,
                jenis: this.row.absd_asal_rujukan,
                type : 'select-ppk-rujukan'
            }
            Gen.apiRest(
                "/do/"+this.$parent.modulePage, 
                {
                    data: data
                }, "POST"
            ).then(res=>{
                if(res.data.status == "success"){
                    this.row.absd_nama_fakes_pelayanan = res.data.response.faskes[0]['nama']
                }else{
                    this.row.absd_nama_fakes_pelayanan = null
                }
            })
        }else{
            this.row.absd_nama_fakes_rujuk = null
        }
    },
    
    getMasterDPJP(e){
      if(e.length >= 5){
          let data = {
              search : e,
              jenis: this.row.absd_jenis,
              type : 'select-dpjp'
          }
          Gen.apiRest(
              "/do/"+this.$parent.modulePage, 
              {
                  data: data
              }, "POST"
          ).then(res=>{
              if(res.data.status == "success"){
                  this.row.absd_dpjp_name = res.data.response.list[0]['nama']
              }else{
                  this.row.absd_dpjp_name = null
              }
          })
      }else{
          this.row.absd_dpjp_name = null
      }
    },

    getMasterKabupaten(e){
        let data = {
            search : e,
            type : 'select-kabupaten'
        }
        Gen.apiRest(
            "/do/"+this.$parent.modulePage, 
            {
                data: data
            }, "POST"
        ).then(res=>{
            if(res.data.status == "success"){
                this.mKabupaten = res.data.response.list
            }else{
                this.mKabupaten = []
            }
        })
    },
    
    getMasterKecamatan(e){
        let data = {
            search : e,
            type : 'select-kecamatan'
        }
        Gen.apiRest(
            "/do/"+this.$parent.modulePage, 
            {
                data: data
            }, "POST"
        ).then(res=>{
            if(res.data.status == "success"){
                this.mKecamatan = res.data.response.list
            }else{
                this.mKecamatan = []
            }
        })
    },

    getDataPBPS(){
        let data = {
            ap_id : this.row.absd_ap_id,
            nik : this.row.ap_nik,
            type : 'select-bpjs-value'
        }

        Gen.apiRest(
            "/do/"+this.$parent.modulePage, 
            {
                data: data
            }, "POST"
        ).then(res=>{
            let data = res.data.data
            
            if(res.data.status == "fail"){
                this.errData = " NIK atau No Kartu Anda tidak ditemukan pada data BPJS. Mohon periksa NIK atau No Kartu Anda."
                return
            }
                
            _.forEach(res.data.data, (v,k)=>{
                this.row[k] = v
            })
            console.log(this.row)
            // this.row.arres_no_bpjs = data.apbd_no_bpjs
            // this.row.arres_no_rujukan = data.arres_no_rujukan

            let statusBPJS = data.apbd_status
            if(statusBPJS == 'MENINGGAL' || statusBPJS == 'KELUAR KEMAUAN SENDIRI' || statusBPJS == 'TIDAK DITANGGUNG' || statusBPJS == 'PENANGGUHAN PESERTA' || statusBPJS == 'NON AKTIF DIAKHIR BULAN' || statusBPJS == 'PENANGGUHAN PEMBAYARAN' || statusBPJS == 'NON AKTIF KARNA DATA GANDA' || statusBPJS == 'USIA ANAK PPU > 21 TH DAN 25 TH' || statusBPJS == 'NON AKTIF KARNA PREMI'){
                this.errDataV2 = data.apbd_status
            }else{
                this.errDataV2 = ""
            }

        })
    },
    ceilData(value) {
        return Math.ceil(value)
    },
    filterName: _.debounce(function (e) {
        this.searchData = e
        Gen.apiRest('/do/' + 'BPJSDataSEP', {
            data: {
                type: 'select-paging-pasien',
                search: e,
            }
        }, 'POST').then(res => {
            this.mPasien = res.data.data
        })
    },10),
    selectName(e){
        if(e){
            let index = this.mPasien.findIndex(x => x.value == e)
            let data = {
                type : "search-pasien",
                search : e,
                regId : this.row.absd_ar_id
            }

            Gen.apiRest(
                "/do/"+this.$parent.modulePage,
                {data: data}, 
                "POST"
            ).then(res=>{
                if(!res.data.data){
                    return this.$swal({
                        title: `Data Tidak Ditemukan`,
                        text: 'Data Pasien Tidak Ditemukan',
                        icon: 'error',
                    })
                }else{
                    let data = res.data.data
                    this.row = {
                        ...this.row,
                        data
                    }
                    this.dataReg = res.data.dataReg

                    if (index !== -1) {
                        this.row.ap_fullname =  this.mPasien[index]['text']
                        this.row.ap_nik =  this.mPasien[index]['nik']
                        this.row.ap_phone_number =  this.mPasien[index]['ap_phone_number']
                        this.row.ap_dom_address =  this.mPasien[index]['ap_dom_address']
                        this.row.ap_dom_rt =  this.mPasien[index]['ap_dom_rt']
                        this.row.ap_dom_rw =  this.mPasien[index]['ap_dom_rw']
                        this.row.ap_dom_kelurahan =  this.mPasien[index]['ap_dom_kelurahan']
                        this.row.ap_dom_kecamatan =  this.mPasien[index]['ap_dom_kecamatan']
                        this.row.ap_dom_kota =  this.mPasien[index]['ap_dom_kota']
                        this.row.ap_code = this.mPasien[index]['code']
                        this.row.ap_gender = this.mPasien[index]['ap_gender']
                        this.row.ap_dob = this.mPasien[index]['ap_dob']

                    }else{
                        this.row.ap_fullname =  null
                        this.row.ap_nik =  null
                        this.row.ap_phone_number =  null
                        this.row.ap_dom_address =  null
                        this.row.ap_dom_rt =  null
                        this.row.ap_dom_rw =  null
                        this.row.ap_dom_kelurahan =  null
                        this.row.ap_dom_kecamatan =  null
                        this.row.ap_dom_kota =  null
                        this.row.ap_code = null
                        this.row.ap_gender = null
                        this.row.ap_dob = null

                    }
                }

                setTimeout(()=>{
                    this.getDataPBPS()
                },1000)
            })
        }
    },
    changePage(page,act){
        if (act == 'min') {
            if (page > 1) {
                page -= 1
                this.mPasien[0]['currentPage'] -= 1
            }
        } else {
            if (page < this.ceilData(this.mPasien[0]['totalRows'] / 10)) {
                page += 1
                this.mPasien[0]['currentPage'] += 1
            }
        }
        let data = {
            type: 'select-paging-pasien',
            page: page,
            search: this.searchData
        }
        Gen.apiRest(
            "/do/" + 'RoReservasi', {
                data: data
            },
            "POST"
        ).then(res => {
            this.mPasien = res.data.data
        })
    },
  },
}
</script>